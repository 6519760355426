<template>
  <a-spin class="loadingClass" v-if="dataValue.loading" tip="正在查询信息 ..."></a-spin>
  <div v-if="!dataValue.loading" style="height: 100%;">
    <div class="showModels">
      <template v-for="aModel in dataValue.allModelList">

        <a-popconfirm title="确认要删除当前型号？" ok-text="是" cancel-text="否" @confirm="allEvent.delModel(aModel)">
          <div class="m">{{ aModel['modelName'] }}</div>
        </a-popconfirm>

      </template>
    </div>
    <div class="menu">
      <template v-for="aRow in dataValue.allTypeList">
        <div v-if="aRow['modelTypeName'] !== dataValue.clickMoneyTypeName" class="aMenu1"
             @click="allEvent.clickMenu(aRow)">
          {{ aRow['modelTypeName'] }}
          <div class="count">({{ aRow['modelCount'] }})</div>
        </div>
        <div v-if="aRow['modelTypeName'] === dataValue.clickMoneyTypeName" class="aMenu2"
             @click="allEvent.clickMenu(aRow)">
          {{ aRow['modelTypeName'] }}
          <div class="count">({{ aRow['modelCount'] }})</div>
        </div>
      </template>
    </div>
    <div class="divAdd">
      <a-button style="margin-right: 20px;" @click="allEvent.modelTypeSet.goOpen">型号类型设置</a-button>
      <a-input placeholder="型号名字" class="xh" v-model:value="dataValue.addModelName"></a-input>
      <a-button type="primary" @click="allEvent.addModel()">查询</a-button>
    </div>
  </div>

  <a-drawer
      title="设置型号类型自动匹配参数"
      width="520"
      :visible="dataValue.modelTypeSet.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="left"
      @close="allEvent.modelTypeSet.goClose"
  >
    <setset @goClose="allEvent.modelTypeSet.goClose" @allType="allHttp.allType"/>
  </a-drawer>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import setset from "@/view/set/model_type_set_set";

export default {
  name : "model_type_set",
  emits : [ 'setMenuKey' ],
  components : { setset },
  setup(props, { emit }) {

    emit('setMenuKey', 'datacenter.xhfl');

    let dataValue = reactive({
      loading : false,
      clickMoneyTypeName : '',
      allTypeList : [],
      allModelList : [],
      addModelName : "",
      modelTypeSet : {
        visible : false
      }
    });


    let allHttp = {

      allType : () => {
        proxy.post('/modelobj/model.type/list', {}).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue['allTypeList'] = res.data;

            if (dataValue.clickMoneyTypeName === "" && dataValue['allTypeList'].length > 0) {
              dataValue.clickMoneyTypeName = dataValue['allTypeList'][0]['modelTypeName'];
            }

            allHttp.getTypeModel();
          }
        })
      },

      getTypeModel : () => {
        proxy.post('/modelobj/model.type/model.list', { "modelTypeName" : dataValue.clickMoneyTypeName }).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue['allModelList'] = res.data;
          }
        })
      }

    }
    allHttp.allType();

    // 所有事件
    let allEvent = {

      modelTypeSet : {

        goOpen : () => {
          dataValue.modelTypeSet.visible = true;
        },

        goClose : () => {
          dataValue.modelTypeSet.visible = false;
        }
      },

      clickMenu : (row) => {
        dataValue.clickMoneyTypeName = row['modelTypeName'];
        allHttp.getTypeModel();
      },

      addModel : () => {
        if (dataValue.addModelName === "") {
          message.error("请输入需要添加的型号名字");
        } else {

          dataValue.loading = true;

          proxy.post('/modelobj/model.type/nas', { "modelName" : dataValue.addModelName }).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.clickMoneyTypeName = res.data['modelTypeName'];
              allHttp.allType();
              dataValue.addModelName = "";

              dataValue.loading = false;
            }
          })
        }
      },

      delModel : (model) => {

        proxy.post('/modelobj/model.type/set', {
          "setType" : 2,
          "modelTypeName" : dataValue.clickMoneyTypeName,
          "modelName" : model['modelName']
        }).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            allHttp.allType();
          }
        })
      }

    }

    return {
      dataValue,
      allEvent,
      allHttp
    }

  }
}
</script>

<style scoped>
.menu { border-right: 1px solid #cccccc; width: 150px; text-align: center; height: 100%; background-color: #f2f9f4; }
.menu .aMenu1 { border-bottom: 1px solid #eeeeee; padding: 10px 0 10px 0; font-size: 13px; cursor: pointer; }
.menu .aMenu2 { border-bottom: 1px solid #eeeeee; padding: 10px 0 10px 0; font-size: 13px; cursor: default;
  background-color: #ffffff; color: red; }
.menu .count { font-size: 10px; color: #cccccc; margin-top: 3px;}
.showModels { float: right; margin-top: 40px; width: calc(100% - 150px); height: calc(100% - 40px); overflow: auto;
  border-top: 1px solid #cccccc; }
.showModels .m { border: 1px solid #eeeeee; float: left; padding: 7px; border-radius: 5px; margin: 8px; background-color: #ffffff; cursor: default; }
.divAdd { position: fixed; top: 65px; padding: 5px 0 10px 0px; left: 149px; width: calc(100% - 160px); height: 42px; background-color: #f2f9f4;
  border-left: 0px solid #eeeeee; }
.divAdd .xh { width: 300px; margin-right: 10px; }

.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>