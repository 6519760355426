<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在保存数据 ..."></a-spin>
  <div v-if="dataValue.loading === false">
    <div class="aRow">
      <div class="modelTypeName">&nbsp;</div>
      <div class="searchCount">查询数</div>
      <div class="quoteCountMonth">报价数</div>
      <div class="orderId">排序</div>
    </div>
    <div class="aRow" v-for="aRow in dataValue.allTypeList">
      <div class="modelTypeName">{{ aRow['modelTypeName'] }}: 大于 > </div>
      <a-input class="searchCount" v-inputNumber v-model:value="aRow['searchCount']"/>
      <a-input class="quoteCountMonth" v-inputNumber v-model:value="aRow['quoteCountMonth']"/>
      <a-input class="orderId" v-inputNumber v-model:value="aRow['orderId']"/>
    </div>

    <div class="nowButton">
      <a-button class="b" @click="allEvent.goClose">取消</a-button>
      <a-button class="b" type="primary" @click="allHttp.saveSize">保存</a-button>
    </div>
  </div>
</template>

<script>
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import {reactive} from "vue";

export default {
  name : "model_type_set_set",
  emits : [ 'goClose', 'allType' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      loading: false,
      allTypeList : []
    });


    let allHttp = {

      allType2 : () => {
        proxy.post('/modelobj/model.type/list', {}).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue['allTypeList'] = res.data;
          }
        })
      },

      saveSize : () => {

        dataValue.loading = true;

        let allSize = dataValue['allTypeList'].length;

        let testReturn = () => {
          if (--allSize === 0) allEvent.goClose2();
        }

        dataValue['allTypeList'].forEach((row) => {

          let input = {
            "modelTypeId" : parseInt(row["modelTypeId"]),
            "searchCount" : parseInt(row["searchCount"]),
            "quoteCountMonth" : parseInt(row["quoteCountMonth"]),
            "orderId" : parseInt(row["orderId"])
          }

          // console.log(input)

          proxy.post('/modelobj/model.type/count.set', input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              testReturn();
            }
          })


        });

      }
    }

    allHttp.allType2();


    let allEvent = {
      goClose : () => {
        emit('goClose');
      },
      goClose2 : () => {
        emit('allType');
        emit('goClose');
      }
    }

    return {
      dataValue,
      allEvent,
      allHttp
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }

.nowButton { clear: both; width: 100%; text-align: center; margin-top: 70px; }
.nowButton .b { margin: 10px; }

.aRow { clear: both; }
.aRow .modelTypeName { float: left; margin: 6px; line-height: 31px; text-align: center; width: 130px; }
.aRow .searchCount { float: left; width: 100px; margin: 6px; text-align: center; }
.aRow .quoteCountMonth { float: left; width: 100px; margin: 6px; text-align: center; }
.aRow .orderId { float: left; width: 60px; margin: 6px; text-align: center; }
</style>